<template>
	<div id="meetList">
		<div class="top" v-loading="loading">
			<div class="kong">
				<el-button v-if="projectInfo.projectType!='single'" type="primary" icon="el-icon-plus" round
					@click="creatorMeet()">创建会议</el-button>
				<el-button v-if="projectInfo.projectType!='single'" type="primary" icon="el-icon-plus" round
					@click="batchCreateMeet()">批量创建会议</el-button>
				<el-button type="warning" icon="el-icon-s-custom" round @click="projectTask()">会议配额</el-button>
				<el-button type="success" icon="el-icon-user" round @click="porjectAssistant()">项目助教</el-button>
				<el-button type="info" icon="el-icon-s-order" round @click="ProjectNotice()">公告配置</el-button>
			</div>
		</div>
		<div class="title">
			<i></i>
			项目主题：{{projectInfo.projectName}}
		</div>
		<div class="tabs">
			<template v-for="(item,i) in list">
				<p :class="item.class == curNow ? 'active':''" :key="i" @click="tabsChange(item)">
					{{item.name}}
					<span v-if="item.class == 'WAIT'">{{recentTotal}}</span>
					<i></i>
				</p>
				<el-divider direction="vertical" :key="i" v-if="i<list.length-1"></el-divider>
			</template>
		</div>
		<!-- 项目列表 -->
		<div class="hzCont" v-loading="loading">
			<div class="list">
				<div class="searth">
					<el-input v-model="searthForm.name" placeholder="请输入会议名称(关键词查找)" clearable> </el-input>
					<el-select v-model="searthForm.location" filterable clearable placeholder="请选择地区">
						<el-option v-for="item in locationList" :key="item.id" :label="item.location"
							:value="item.location">
						</el-option>
					</el-select>
					<el-button @click="getMeetingList()" slot="append" type="primary"
						icon="el-icon-search">搜索</el-button>
					<el-button @click="searthForm={},getMeetingList()" slot="append" type="info"
						icon="el-icon-refresh">重置</el-button>

				</div>

				<h1>会议列表</h1>
				<div class="item" v-for="item in meetingList" :key="item.id" @click="toMeetingDetails(item)">
					<div class="tags" v-if="item.meetingType">
						<div class="type" v-if="item.meetingType == 'single'&&item.parentId">
							<img src="../../../assets/imgs/video.png">追加会
						</div>
						<div class="type" v-else-if="item.meetingType == 'single'">
							<img src="../../../assets/imgs/video.png">单场会
						</div>
						<div class="type" v-else-if="item.meetingType == 'series'">
							<img src="../../../assets/imgs/video2.png">系列会
						</div>
						<div class="type" v-else></div>
						<div class="idNum">
							<template v-if="item.parentId">
								源自:{{item.parentId}}
							</template>
							&nbsp;
							编号:{{item.id}}
						</div>
					</div>
					<div class="main" v-if="item.meetingTitle">
						<div class="left">
							<div class="tit">会议主题：{{item.meetingTitle}}</div>
							<div class="time" v-if="item.meetingStartTime">
								会议时间：
								{{ $moment(item.meetingStartTime).format('YYYY-MM-DD') }}
								{{ $moment(item.meetingStartTime).format('LT') }}
							</div>
							<div class="type">
								<el-tag size="mini" v-if="item.meetingStatus == 'WAIT'" type="info">待开始</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'WAIT_CLAIM'"
									type="warning">待认领</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'LIVING'" type="info">进行中</el-tag>
								<!-- <el-tag size="mini" v-if="item.meetingStatus == 'FINISH'" type="info">已结束</el-tag> -->
								<el-tag size="mini" v-if="item.meetingStatus == 'FINISH'" type="info">待上传证明</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'UPLOAD'" type="info">待上传证明</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'AI_REVIEW'" type="success">预审中</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'AI_REVIEW_ERROR'"
									type="danger">预审驳回</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'AI_REVIEW_ERROR_ACCEPT'"
									type="success">官员审核</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'AI_REVIEW_SUCCESS'"
									type="success">官员审核</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'OFFICIAL_ERROR'"
									type="danger">官员驳回</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'OFFICIAL_SUCCESS'"
									type="success">财务审核</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'ERROR'" type="danger">财务驳回</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'SUCCESS'" type="success">审核通过</el-tag>
								<el-tag size="mini" v-if="item.location">{{item.location}}</el-tag>
								<el-tag size="mini" v-if="item.reimbursementNum" type="primary">
									编号:{{item.reimbursementNum}}</el-tag>
							</div>
						</div>
						<div class="opts">
							<el-button type="primary" @click.stop="additionalMeeting(item)"
								v-if="item.meetingType == 'single'&&!item.parentId">追加会议</el-button>
							<el-button @click.stop="claimMeeting(item)" type="warning"
								v-if="item.meetingStatus == 'WAIT_CLAIM'">认领会议</el-button>
							<el-button @click.stop="toMeetingDetails(item)" type="primary" v-else>会议详情</el-button>


							<el-button @click.stop="uploadContent(item)" v-if="item.meetingStatus == 'UPLOAD'"
								type="warning">劳务结算</el-button>
							<el-button @click.stop="uploadContent(item)"
								v-if="item.meetingStatus == 'AI_REVIEW_ERROR'||item.meetingStatus == 'OFFICIAL_ERROR'||item.meetingStatus == 'ERROR'"
								type="warning">重新上传</el-button>
							<!-- <el-button type="primary" @click.stop="supplierSettlement(item)"
								v-if="item.meetingStatus!='WAIT_CLAIM'&&item.meetingStatus!='WAIT'&&item.meetingStatus!='LIVING'">供应商结算</el-button> -->
						</div>
					</div>

					<template v-for="children in item.children">
						<div class="tags" :key="children.id">
							<div class="type" v-if="children.meetingType == 'single'&&children.parentId">
								<img src="../../../assets/imgs/video.png">追加会
							</div>
							<div class="type" v-else-if="children.meetingType == 'single'">
								<img src="../../../assets/imgs/video.png">单场会
							</div>
							<div class="type" v-else-if="children.meetingType == 'series'">
								<img src="../../../assets/imgs/video2.png">系列会
							</div>
							<div class="type" v-else></div>
							<div class="idNum">
								<template v-if="children.parentId">
									源自:{{children.parentId}}
								</template>
								&nbsp;
								编号:{{children.id}}
							</div>
						</div>
						<div class="main" :key="children.id">
							<div class="left">
								<div class="tit">会议主题：{{children.meetingTitle}}</div>
								<div class="time" v-if="children.meetingStartTime">
									会议时间：
									{{ $moment(children.meetingStartTime).format('YYYY-MM-DD') }}
									{{ $moment(children.meetingStartTime).format('LT') }}
								</div>
								<div class="type">
									<el-tag size="mini" v-if="children.meetingStatus == 'WAIT'" type="info">待开始</el-tag>
									<el-tag size="mini" v-if="children.meetingStatus == 'WAIT_CLAIM'"
										type="warning">待认领</el-tag>
									<el-tag size="mini" v-if="children.meetingStatus == 'LIVING'"
										type="info">进行中</el-tag>
									<!-- <el-tag size="mini" v-if="children.meetingStatus == 'FINISH'" type="info">已结束</el-tag> -->
									<el-tag size="mini" v-if="children.meetingStatus == 'FINISH'"
										type="info">待上传证明</el-tag>
									<el-tag size="mini" v-if="children.meetingStatus == 'UPLOAD'"
										type="info">待上传证明</el-tag>
									<el-tag size="mini" v-if="children.meetingStatus == 'AI_REVIEW'"
										type="success">预审中</el-tag>
									<el-tag size="mini" v-if="children.meetingStatus == 'AI_REVIEW_ERROR'"
										type="danger">预审驳回</el-tag>
									<el-tag size="mini" v-if="children.meetingStatus == 'AI_REVIEW_ERROR_ACCEPT'"
										type="success">官员审核</el-tag>
									<el-tag size="mini" v-if="children.meetingStatus == 'AI_REVIEW_SUCCESS'"
										type="success">官员审核</el-tag>
									<el-tag size="mini" v-if="children.meetingStatus == 'OFFICIAL_ERROR'"
										type="danger">官员驳回</el-tag>
									<el-tag size="mini" v-if="children.meetingStatus == 'OFFICIAL_SUCCESS'"
										type="success">财务审核</el-tag>
									<el-tag size="mini" v-if="children.meetingStatus == 'ERROR'"
										type="danger">财务驳回</el-tag>
									<el-tag size="mini" v-if="children.meetingStatus == 'SUCCESS'"
										type="success">审核通过</el-tag>
									<el-tag size="mini" v-if="children.location">{{children.location}}</el-tag>
									<el-tag size="mini" v-if="children.reimbursementNum" type="primary">
										编号:{{children.reimbursementNum}}</el-tag>
								</div>
							</div>
							<div class="opts">
								<!-- <el-button type="primary" @click.stop="additionalMeeting(children)"
									v-if="children.meetingType == 'single'&&!children.parentId">追加会议</el-button> -->
								<el-button @click.stop="claimMeeting(children)" type="warning"
									v-if="children.meetingStatus == 'WAIT_CLAIM'">认领会议</el-button>
								<el-button @click.stop="toMeetingDetails(children)" type="primary"
									v-else>会议详情</el-button>

							</div>
						</div>
						<el-divider :key="children.id"></el-divider>
					</template>
				</div>

				<div class="null" v-if="!meetingList[0]">
					<img :src="require('@/assets/imgs/EmptyState.png')" alt="">
					<p>暂时没有会议内容</p>
				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="size"
				:current-page.sync="page" class="pagination" @current-change="getMyProject"
				:hide-on-single-page="false"></el-pagination>
		</div>

		<!-- 供应商结算 -->
		<el-dialog custom-class="settleAccounts" v-if="settleAccountsShow" :visible.sync="settleAccountsShow"
			title="请选择收款方式及费用类型" width="600px" center>
			<el-form ref="form" :model="accountForm" label-position="left">
				<el-row>
					<el-col :span="12">
						<el-form-item label="收款方式:">
							<el-select size="small" v-model="accountForm.collectWay" placeholder="请选择活动区域">
								<el-option v-for="(item,i) in collectWayEnums" :key="i" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="费用类型:">
							<el-select size="small" v-model="accountForm.feeType" placeholder="请选择活动区域">
								<el-option label="会议类" value="0"></el-option>
								<el-option label="非会议类" value="1" disabled></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="tips">
				<h3>请仔细阅读以下说明</h3>
				<p>如有 <b>一次性收款</b> ，直接选择“一次性收款并结项”</p>
				<p>如有 <b>两次收款</b> ，第一次选择“申请首次收款”，第二次选择“申请尾款”</p>
				<p>如有 <b>分三次及三次以上收款</b> ，第一次选择“申请首次收款”，中间付款选择“申请阶段收款”，最后一笔选择“申请尾款”</p>
				<p><b>据实结算</b> 的收款方式仅支持费用类型是会议类，框架服务模式，按场次收款的项目，可多次申请</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="toSupplierSettlement">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 公告 -->
		<el-dialog custom-class="Notice" v-if="NoticeShow" :visible.sync="NoticeShow"
			:title="`${projectInfo.projectName} 公告`" width="800px" center>
			<div class="NoticeMain" v-html="Notice.message"></div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="checkNotice">我已阅读并知晓</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getMeetingUrl,
		officialMeetingNum,
		officialMeetList,
		getCollectWay,
		getOrderByMeetId,
		getLocationList,
		officialGetProjectNotice,
		officialacceptProjectNotice
	} from '@/request/api.js';
	export default {
		name: 'meetList',
		components: {},
		data() {
			return {
				loading: false,
				projectId: '',
				projectNmae: '',
				projectInfo: '',
				locationList: '',
				curNow: "WAIT",
				upNum: 0,
				page: 1,
				size: 5,
				recentTotal: 0,
				total: 0,
				meetingList: [],
				todayMeetList: [],
				list: [{
					name: '近期会议',
					class: 'WAIT',
				}, {
					name: '待会后上传',
					class: 'UPLOAD',
				}, {
					name: '审核中会议',
					class: 'REVIEW',
				}, {
					name: '已结束会议',
					class: 'FINISH',
				}],
				message: '',
				searthForm: {
					name: "",
					project: "",
				},
				projectList: [],
				timer: null,
				settleAccountsShow: false,
				accountForm: {
					feeType: '0',
					collectWay: ""
				},
				collectWayEnums: [],
				orderData: '',
				ckMeetingId: '',

				Notice: '', // 公告
				NoticeShow: false
			}
		},
		created() {
			this.projectInfo = JSON.parse(localStorage.getItem('projectDetails'));
			this.getUploadMeetingNum();
			this.getMeetingFirst()
			this.getMeetingList();
			this.getLocationListFun();
			this.getNotice()
		},
		mounted() {},
		methods: {
			checkNotice() {

				officialacceptProjectNotice({
					noticeId: this.Notice.id,
					projectId: this.projectInfo.id
				}).then(res => {
					if (res.code == 200) {
						this.NoticeShow = false;
					}
				})
			},

			getNotice() {
				officialGetProjectNotice({
					projectId: this.projectInfo.id
				}).then(res => {
					if (res.code == 200) {
						this.Notice = res.data
						if (!this.Notice.isCheck) {
							this.NoticeShow = true
						}
						// console.log(this.Notice)
					}
				})
			},

			// 配置公告
			ProjectNotice() {
				this.$router.push({
					name: 'projectNotice',
					query: {
						pId: this.projectInfo.id
					}
				})
			},

			// 获取地区列表
			getLocationListFun() {
				getLocationList().then(res => {
					if (res.code == 200) {
						this.locationList = res.data
					}
				})
			},
			// 会议配额
			projectTask() {
				this.$router.push({
					name: 'projectTask',
					query: {
						pId: this.projectInfo.id
					}
				})
			},


			// 查看项目助教
			porjectAssistant() {
				this.$router.push({
					name: 'myAssistant',
					query: {
						pId: this.projectInfo.id
					}
				})
			},

			// 跳转供应商结算页面
			toSupplierSettlement() {
				this.$router.push({
					name: 'supplierSettlement',
					query: {
						mId: this.ckMeetingId,
						collectWay: this.accountForm.collectWay,
						feeType: this.accountForm.feeType
					}
				})
			},
			// 供应商结算
			// supplierSettlement(item) {
			// 	this.ckMeetingId = item.id;
			// 	this.loading = true;

			// 	this.getCollectWayFun(item);

			// 	getOrderByMeetId({
			// 		meetingId: item.id,
			// 	}).then(res => {
			// 		this.orderData = res.data;
			// 		if (this.orderData) {
			// 			this.loading = false;
			// 			this.toSupplierSettlement()
			// 			console.log('有记录')
			// 		} else {
			// 			this.loading = false;
			// 			this.settleAccountsShow = true;
			// 			console.log('无记录')
			// 		}
			// 	})
			// },
			// getCollectWayFun(item) {
			// 	getCollectWay({
			// 		// projectId: this.projectId,
			// 		meetingId: item.id
			// 	}).then(res => {
			// 		if (res.code == 200) {
			// 			// console.log(res.data)
			// 			this.collectWayEnums = res.data;
			// 			if (this.collectWayEnums.length == 1) {
			// 				this.accountForm.collectWay = this.collectWayEnums[0].value;
			// 			}
			// 		};
			// 	});
			// },
			// 获取项目列表
			// getProjectList(query) {
			// 	let data = {
			// 		current: 1,
			// 		size: 30,
			// 		projectName: query
			// 	}
			// 	console.log(data)
			// 	projectList(data).then(res => {
			// 		if (res.code == 200) {
			// 			this.projectList = res.data.records;
			// 		}
			// 	})
			// },

			// 认领会议/修改会议信息
			claimMeeting(item) {
				this.$router.push({
					name: 'officialCreatemeet',
					query: {
						pId: this.projectInfo.id,
						mId: item.id
					}
				})
			},

			// 查看会议详情
			toMeetingDetails(item) {
				console.log(JSON.parse(JSON.stringify(item)))
				if (item.meetingStatus == "WAIT_CLAIM") {
					this.claimMeeting(item);
				} else {
					this.$router.push({
						name: 'officialMeetingDetail',
						query: {
							meetingId: item.id,
							now: '1'
						}
					})
				}

			},
			uploadContent(item) {
				console.log(item)
				this.$router.push({
					name: 'officialUploadFile',
					query: {
						id: item.id,
						// status: item.meetingStatus,
						// now: '4'
					}
				})
			},

			goDoctorList() {
				this.$router.push({
					name: 'doctor',
				})
			},
			// 追加会议
			additionalMeeting(item) {
				this.$router.push({
					name: 'officialCreatemeet',
					query: {
						pId: this.projectInfo.id,
						parentId: item.id
					}
				})
			},
			// 创建会议
			creatorMeet() {
				this.$router.push({
					name: 'officialCreatemeet',
					query: {
						pId: this.projectInfo.id
					}
				})
			},

			// 批量创建会议
			batchCreateMeet() {
				this.$router.push({
					name: 'batchCreateMeet',
					query: {
						pId: this.projectInfo.id
					}
				})
			},

			// 请求防抖
			// debounce(fn, delay) {
			// 	let timer = null;
			// 	return function() {
			// 		let context = this;
			// 		let args = arguments;
			// 		if (timer) clearTimeout(timer);
			// 		timer = setTimeout(function() {
			// 			fn.apply(context, args);
			// 		}, delay);
			// 	}
			// },

			// 获取会议列表
			getMeetingList() {
				let data = {
					projectId: this.projectInfo.id,
					size: this.size,
					current: this.page,
					meetingType: this.curNow
				};
				if (this.searthForm.name) data.meetingTitle = this.searthForm.name;
				if (this.searthForm.location) data.location = this.searthForm.location;
				else data.meetingTitle = '';

				officialMeetList(data).then(res => {
					if (res.code == 200) {
						let resData = res.data.records
						resData.map(item => {
							if (item.id == item.parentId) delete item.parentId;
							return item;
						})

						this.meetingList = resData;
						this.total = res.data.total;
					};
				});
			},
			getMeetingFirst() {
				let data = {
					projectId: this.projectInfo.id,
					meetingType: "WAIT"
				};
				officialMeetList(data).then(res => {
					if (res.code == 200) {
						this.recentTotal = res.data.total;
					};
				});
			},
			// 查询会后待上传数量
			getUploadMeetingNum() {
				let data = {
					projectId: this.projectInfo.id
				};
				officialMeetingNum(data).then(res => {
					if (res.code == 200) {
						this.upNum = res.data;
					};
				});
			},

			// 进入会议
			enterTheMeeting(item) {
				let data = {
					meetingId: item.id
				}
				getMeetingUrl(data).then(res => {
					if (res.code == 200) {
						// console.log(res)
						window.open(res.data)
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
						this.$notify({
							type: 'error',
							title: '错误',
							message: res.msg,
							position: 'bottom-right'
						});
					}
				})
			},

			// 获取今日会议列表
			// getTodayMeeting() {
			// 	let data = {
			// 		// projectId: this.projectId
			// 	};
			// 	todayMeeting(data).then(res => {
			// 		if (res.code == 200) {
			// 			let data = res.data;
			// 			data.map(item => {
			// 				item.doctors = item.doctors.split(',').join("，");
			// 				return item;
			// 			})

			// 			this.todayMeetList = data;
			// 			// this.$forceUpdate();
			// 		}
			// 	})
			// },
			// getOederData(id) {
			// },

			// 分页切换
			getMyProject(e) {
				// console.log(e)
				this.page = e;
				this.getMeetingList();
			},

			// 切换会议选项
			tabsChange(item) {
				this.curNow = item.class;
				this.page = 1;
				this.getMeetingList();
			},

			sectionChange(item) {
				this.curNow = item.class;
				this.page = 1;
				this.getMeetingList();
				this.curNow = 'FINISH';
			}
		}
	}
</script>

<style lang="scss" scoped>
	#meetList {
		display: flex;
		height: auto;
		flex-direction: column;
		padding: 20px 30px;

		.top {
			padding: 20px 20px;
			box-shadow: 0 2px 15px rgba(203, 203, 203, 0.31);
			border-radius: 10px;
			margin-bottom: 20px;
		}

		.title {
			font-size: 20px;
			padding: 0 20px 0 30px;
			position: relative;
			margin: 10px 0 20px;

			i {
				top: 50%;
				left: 0px;
				width: 20px;
				height: 20px;
				position: absolute;
				border-radius: 50%;
				background-color: #3563E9;
				border: 5px solid #C2D0F8;
				transform: translateY(-50%);
			}
		}

		.kong {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			.el-button {
				width: 200px;
				height: 60px;
				border-radius: 30px;
				font-size: 16px;
				margin: 0 30px 0 0;
			}
		}

		.tabs {
			display: flex;
			flex-direction: row;
			align-items: center;
			// justify-content: space-around;
			margin-bottom: 20px;

			.el-divider {
				width: 2px;
				height: 30px;
				margin: 0;
			}

			p {
				width: 200px;
				padding: 10px 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				cursor: pointer;
				line-height: 2.5;
				position: relative;

				i {
					width: 100%;
					left: 0;
					bottom: 0;
					height: 0;
					background-color: #0065FF;
					position: absolute;
					transition: all .3s;
				}

				span {
					margin-left: 10px;
					height: 26px;
					width: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					background-color: #007AFF;
					border-radius: 5px;
					color: #fff;
				}
			}

			.active i {
				height: 6px;
			}
		}

		.hzCont {
			flex: 1;
			padding: 20px 0;
			display: flex;
			flex-direction: column;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;




			::v-deep .searth {
				display: flex;
				margin-bottom: 20px;

				.el-input,
				.el-select {
					flex: 1;
					margin-right: 10px;
				}
			}

			.list {
				flex: 1;

				h1 {
					color: #000;
					font-size: 20px;
					margin-bottom: 20px;
				}

				// .itemToday {
				// 	display: flex;
				// 	margin-bottom: 20px;
				// 	flex-direction: row;
				// 	padding: 20px;
				// 	box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				// 	transition: all .3s;
				// 	border-radius: 10px;
				// 	align-items: center;

				// 	&:hover {
				// 		background-color: #E8EEFC;
				// 	}

				// 	.main {
				// 		flex: 1;
				// 		display: flex;
				// 		flex-direction: column;

				// 		.tag {
				// 			display: flex;
				// 			margin-bottom: 10px;

				// 			span {
				// 				height: 20px;
				// 				background-color: #007AFF;
				// 				font-size: 12px;
				// 				border-radius: 5px;
				// 				color: #fff;
				// 				display: flex;
				// 				align-items: center;
				// 				padding: 0 10px;
				// 			}
				// 		}

				// 		.tit {
				// 			font-size: 16px;
				// 			color: #000;
				// 			margin-bottom: 10px;
				// 			line-height: 1;
				// 		}

				// 		.time {
				// 			font-size: 13px;
				// 			color: #007AFF;
				// 			line-height: 1;
				// 		}
				// 	}

				// 	.goLive {
				// 		background: #0065FF;
				// 		color: #fff;
				// 		padding: 0 25px;
				// 		height: 28px;
				// 	}
				// }

				// .el-carousel {
				// 	margin-bottom: 10px;
				// }

				// .el-carousel__item {
				// 	display: flex;
				// 	flex-direction: row;

				// 	.bgImg {
				// 		flex: 1;
				// 		height: 100%;
				// 		overflow: hidden;
				// 		object-fit: cover;

				// 		img {
				// 			width: 100%;
				// 			height: 100%;
				// 			display: block;
				// 			object-fit: cover;
				// 			border-radius: 20px;
				// 		}
				// 	}

				// 	.main {
				// 		flex: 1;
				// 		padding: 20px 0 20px 40px;
				// 		display: flex;
				// 		flex-direction: column;
				// 		align-items: space-between;
				// 		justify-content: space-between;

				// 		>div {
				// 			font-size: 16px;
				// 			display: flex;
				// 			align-items: center;
				// 			color: #112950;

				// 			img {
				// 				height: 16px;
				// 				margin-right: 5px;
				// 			}
				// 		}

				// 		.tit {
				// 			font-size: 22px;
				// 			color: #000;
				// 			word-wrap: break-word;
				// 			white-space: normal;
				// 			word-break: break-all;
				// 			// overflow: hidden;
				// 			// white-space: nowrap;
				// 			// text-overflow: ellipsis;
				// 		}

				// 		.goLive {
				// 			color: #fff;
				// 			height: 50px;
				// 			background-color: #D43030;
				// 		}
				// 	}
				// }

				.item {
					display: flex;
					margin-bottom: 20px;
					flex-direction: column;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					transition: all .3s;
					border-radius: 10px;

					&:hover {
						background-color: #E8EEFC;
					}

					.el-divider {
						margin: 10px 0;

						&:last-child {
							display: none;
						}
					}

					.tags {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 10px;

						.type {
							display: flex;
							align-items: center;
							color: #000;
							font-size: 12px;

							img {
								margin-right: 5px;
								width: 20px;
								height: 15px;
							}
						}

						.idNum {
							font-size: 12px;
							color: #999;
						}
					}

					.main {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.left {
							.tit {
								font-size: 16px;
								color: #333;
								margin-bottom: 10px;
								line-height: 1;
								font-weight: bold;
							}

							.time {
								font-size: 13px;
								color: #666;
								line-height: 1;
								margin-bottom: 10px;
							}

							.type {
								display: flex;

								.el-tag {
									margin-right: 4px;
								}

								.el-button--text {
									padding: 0;
									font-size: 13px;
								}

								.WAIT {
									color: #007AFF;
								}

								.FINISH {
									color: #999;
								}
							}
						}

						.opts {
							display: flex;
							align-items: flex-end;

							.el-button {
								padding: 0 25px;
								height: 28px;
							}

							.el-button--primary {
								background-color: #0065ff;
							}

							.el-button--warning {
								background-color: #E6A23C;
							}

							.el-button--danger {
								background-color: #F56C6C;
							}

							.agreement {
								background-color: #D43030;
								color: #fff;
								padding: 0 25px;
								height: 28px;
							}
						}
					}
				}

				.null {
					width: 100%;
					padding: 100px;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;

					img {
						width: 200px;
					}

					p {
						font-size: 18px;
						color: #A6A6A6;
					}
				}
			}

			.el-pagination {
				margin: auto;
			}
		}

	}

	::v-deep .settleAccounts {

		.el-dialog__body {
			padding-bottom: 10px;
		}

		.tips {
			background-color: #f6cdbb;
			padding: 20px;
			border-radius: 10px;

			h3 {
				margin-bottom: 10px;
			}

			p {
				margin-bottom: 5px;

				b {
					color: red;
				}
			}
		}
	}

	::v-deep .Notice {
		margin: 0 !important;
		max-height: 95vh;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		// .el-dialog__header {
		// 	padding: 0;
		// }

		// .el-dialog__body {
		// 	padding: 0;
		// }

		.el-dialog__headerbtn {
			top: 20px;
			right: 20px;
		}
	}
</style>